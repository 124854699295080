const Footer = () => {
  return (
    <footer>
      <img
        src="https://ae.studio/img/same-day-skunkworks/watermark_negative.png"
        alt="Same day skunkworks"
        className="watermark rotating"
        onClick={() => window.open("https://ae.studio/same-day-skunkworks")}
      />
    </footer>
  );
};

export default Footer;
