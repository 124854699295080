import { useState } from "react";
import { Dialog } from '@headlessui/react'

const INIT = "INIT";
const SUBMITTING = "SUBMITTING";
const ERROR = "ERROR";
const SUCCESS = "SUCCESS";
const formStates = [INIT, SUBMITTING, ERROR, SUCCESS];
const formStyles = {
  "id": "clpsl4mk5008ml20ozvjha4wy",
  "name": "Default",
  "formStyle": "inline",
  "placeholderText": "you@example.com",
  "formFont": "Inter",
  "formFontColor": "#0E041C",
  "formFontSizePx": 14,
  "buttonText": "Get Notified!",
  "buttonFont": "Inter",
  "buttonFontColor": "#ffffff",
  "buttonColor": "#f5811b",
  "buttonFontSizePx": 14,
  "successMessage": "Awesome, we'll hit you up once it's ready",
  "successFont": "Inter",
  "successFontColor": "#ffffff",
  "successFontSizePx": 14,
  "userGroup": "markdownnotestab.com"
}
const domain = "app.loops.so"

export default function SignUpFormReact() {
  const [email, setEmail] = useState("");
  const [formState, setFormState] = useState(INIT);
  const [errorMessage, setErrorMessage] = useState("");

  const resetForm = () => {
    setEmail("");
    setFormState(INIT);
    setErrorMessage("");
  };

  /**
   * Rate limit the number of submissions allowed
   * @returns {boolean} true if the form has been successfully submitted in the past minute
   */
  const hasRecentSubmission = () => {
    const time = new Date();
    const timestamp = time.valueOf();
    const previousTimestamp = localStorage.getItem("loops-form-timestamp");

    // Indicate if the last sign up was less than a minute ago
    if (
      previousTimestamp &&
      Number(previousTimestamp) + 60 * 1000 > timestamp
    ) {
      setFormState(ERROR);
      setErrorMessage("Too many signups, please try again in a little while");
      return true;
    }

    localStorage.setItem("loops-form-timestamp", timestamp.toString());
    return false;
  };

  const handleSubmit = (event) => {
    // Prevent the default form submission
    event.preventDefault();

    // boundary conditions for submission
    if (formState !== INIT) return;
    if (!isValidEmail(email)) {
      setFormState(ERROR);
      setErrorMessage("Please enter a valid email");
      return;
    }
    if (hasRecentSubmission()) return;
    setFormState(SUBMITTING);

    // build body
    const formBody = `userGroup=${encodeURIComponent(
      formStyles.userGroup
    )}&email=${encodeURIComponent(email)}`;

    // API request to add user to newsletter
    fetch(`https://${domain}/api/newsletter-form/${formStyles.id}`, {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => [res.ok, res.json(), res])
      .then(([ok, dataPromise, res]) => {
        if (ok) {
          resetForm();
          setFormState(SUCCESS);
        } else {
          dataPromise.then((data) => {
            setFormState(ERROR);
            setErrorMessage(data.message || res.statusText);
            localStorage.setItem("loops-form-timestamp", "");
          });
        }
      })
      .catch((error) => {
        setFormState(ERROR);
        // check for cloudflare error
        if (error.message === "Failed to fetch") {
          setErrorMessage("Too many signups, please try again in a little while");
        } else if (error.message) {
          setErrorMessage(error.message);
        }
        localStorage.setItem("loops-form-timestamp", "");
      });
  };

  const isInline = formStyles.formStyle === "inline";

  switch (formState) {
    case SUCCESS:
      return (
        <>
          <Dialog.Title
            as="h3"
            className="text-2xl font-medium text-white"
          >
            ✅ Done!
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-md text-gray-300">
              Awesome, we'll hit you up once it's ready
            </p>
          </div>
        </>
      );
    case ERROR:
      return (
        <>
          <SignUpFormError />
          <BackButton />
        </>
      );
    default:
      return (
        <>
          <Dialog.Title
            as="h3"
            className="text-2xl font-medium text-white"
          >
            🚧 Under development
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-md text-gray-300">
              We're currently working on a web version of the app. If you'd like to be notified when it's ready, please enter your email below.
            </p>
          </div>

          <div className="mt-4">
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: isInline ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <input
                type="text"
                name="email"
                placeholder={formStyles.placeholderText}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
                style={{
                  color: formStyles.formFontColor,
                  fontFamily: `'${formStyles.formFont}', sans-serif`,
                  fontSize: `${formStyles.formFontSizePx}px`,
                  margin: isInline ? "0px 10px 0px 0px" : "0px 0px 10px",
                  width: "100%",
                  maxWidth: "300px",
                  minWidth: "100px",
                  background: "#FFFFFF",
                  border: "1px solid #D1D5DB",
                  boxSizing: "border-box",
                  boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px",
                  borderRadius: "6px",
                  padding: "8px 12px",
                }}
              />
              <SignUpFormButton />
            </form>
          </div>
          
        </>
      );
  }

  function SignUpFormError() {
    return (
      <>
        <Dialog.Title
          as="h3"
          className="text-2xl font-medium text-white"
        >
          🛠️ Oops!
        </Dialog.Title>
          <div className="mt-2">
            <p className="text-md text-gray-300">
            {errorMessage || "Something went wrong, please try again"}
            </p>
          </div>
      </>
      
    );
  }

  function BackButton() {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <button
        style={{
          color: "#6b7280",
          font: "14px, Inter, sans-serif",
          margin: "10px auto",
          textAlign: "center",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          textDecoration: isHovered ? "underline" : "none",
        }}
        onMouseOut={() => setIsHovered(false)}
        onMouseOver={() => setIsHovered(true)}
        onClick={resetForm}
      >
        &larr; Back
      </button>
    );
  }

  function SignUpFormButton({ props }) {
    return (
      <button
        type="submit"
        style={{
          background: formStyles.buttonColor,
          fontSize: `${formStyles.buttonFontSizePx}px`,
          color: formStyles.buttonFontColor,
          fontFamily: `'${formStyles.buttonFont}', sans-serif`,
          width: isInline ? "min-content" : "100%",
          maxWidth: "300px",
          whiteSpace: isInline ? "nowrap" : "normal",
          height: "38px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          padding: "9px 17px",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
          borderRadius: "6px",
          textAlign: "center",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
          border: "none",
          cursor: "pointer",
        }}
      >
        {formState === SUBMITTING ? "Please wait..." : formStyles.buttonText}
      </button>
    );
  }
}

function isValidEmail(email) {
  return /.+@.+/.test(email);
}
