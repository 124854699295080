const Nav = () => {
  return (
    <nav class="flex items-center fixed z-20 w-full">
      <a target="_blank" class="flex md:flex-row bg-[#A5B4FC] p-3 md:p-2.5 justify-between md:justify-center items-center gap-1 md:gap-4 text-sm font-normal md:h-11 w-full" 
        href="https://ae.studio?utm_source=sds&amp;utm_medium=referral&amp;utm_campaign=markdownnotestab&amp;utm_content=top-bar" rel="noreferrer"><span class="font-medium">
        Made with 🧡 by <span class="underline">AE Studio</span></span>
        <span class="hidden md:flex">•</span>
        <span class="hidden md:block">See what we could build for you</span>
        <span class="font-semibold bg-[#FA6E62] hover:bg-[#fa7b70] text-white py-2 px-4 rounded transition">Learn more →</span>
      </a>
    </nav>
  )
}

export default Nav